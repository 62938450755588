





































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SurveyModule = namespace("SurveyModule");
const BusinessModule = namespace("BusinessModule");
const LocationModule = namespace("LocationModule");
import { Location } from "../../store/modules/location/types";
import { Survey } from "../../store/modules/survey/types";
import { Business } from "../../store/modules/business/types";
import WishBanner from "../../components/WishBanner.vue";
import Icon from "../../components/Icon.vue";
import SurveyAdd from './SurveyAdd.vue';
import SendSurveyLink from './SendSurveyLink.vue';
import { sortStringAttributes } from '../../services';

@Component({
  components: {
    WishBanner,
    Icon,
    SurveyAdd,
    SendSurveyLink
  }
})
export default class SurveyList extends Vue {
  @SurveyModule.Getter
  public surveys: Survey[];

  @BusinessModule.Getter
  public businesses: Business[];

  @LocationModule.Getter
  public locations: Location[];

  public searchTerm = "";
  public visible = false;
  public fetching = false;
  public surveyLinkVisible = false;
  public selectedSurvey;
  public profile = JSON.parse(localStorage.getItem('profile') || '{}') || {}

  public columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sortStringAttributes(a.name, b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => sortStringAttributes(a.description, b.description),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => sortStringAttributes(a.status, b.status),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
    },
  ];
  
  @SurveyModule.Action
  public load!: () => Promise<Survey[]>;

  @SurveyModule.Action
  public reset!: () => Promise<void>;

  @BusinessModule.Action
  public loadBusinesses: () => Promise<Business[]>;

  @LocationModule.Action
  public loadAllLocations!: () => Promise<Location[]>;

  @SurveyModule.Action
  public search!: (term:string) => Promise<Survey[]>;

  async beforeDestroy() {
    this.reset();
  }
  
  async mounted() {
    this.fetching = true;
    try {
      if (this.profile.scope === 'GSA') {
        await this.loadBusinesses();
        this.columns.unshift({
          title: "Business",
          scopedSlots: { customRender: 'businessName' },
          slots: { title: "businessName" }
        })
      }
      await this.load();
    } catch(err) {
      console.log(err);
    }
    this.fetching = false;
    await this.loadAllLocations();
  }

  handleSendSurveyLink(survey) {
    this.selectedSurvey = survey;
    if (this.profile.scope !== 'GSA') {
      this.selectedSurvey.businessId = this.profile.businessId;
    }
    this.surveyLinkVisible = true;
  }

  getBusinessName(businessId:string) {
    const response = this.businesses.filter(record => record.businessId === businessId)
    if (response.length > 0) return response[0].name;
    return '';
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
  }

  surveyAdded() {
    this.visible = false;
    this.load();
  }

  surveyLinkAdded() {
    this.surveyLinkVisible = false;
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/survey/${record.surveyId}/configure`);
        },
      },
    };
  }
}
