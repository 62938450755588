<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item style="display:none;">
        <a-select :disabled="whileSaving" v-decorator="['type']" default-value="custom" size="large" placeholder="Type*">
          <!-- <a-select-option value="pool">
            Select from Pool
          </a-select-option> -->
          <a-select-option value="custom">
            Custom
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="type==='pool'">
        <a-select :disabled="whileSaving" v-decorator="['globalQuestionId', {
              rules: [
                { required: true, message: 'Please select question from pool!' },
              ],
            },]" default-value="Male" size="large" placeholder="Question*">
          <a-select-option :value="item.questionId" v-for="item in globalQuestions" v-bind:key="item.id">
            {{ item.title }} | {{ item.answer }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input
        :disabled="globalQuestionId"
        v-decorator="['title', {
              rules: [
                { required: true, message: 'Please input Question name!' },
              ],
            },]"
          size="large"
          placeholder="Question Name*"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-textarea
        :readonly="globalQuestionId"
        rows=5
        v-decorator="['name', {
              rules: [
                { required: true, message: 'Please input Question Text!' },
              ],
            },]"
          size="large"
          placeholder="Question Text*"
        >
        </a-textarea>
      </a-form-item>
      <a-form-item>
        <a-select :disabled="whileSaving" v-decorator="['answer', {
              rules: [
                { required: true, message: 'Please input Safe Answer!' },
              ],
            },]" default-value="yes" size="large" placeholder="Safe Answer*">
          <a-select-option value="yes">
            Yes
          </a-select-option>
          <a-select-option value="no">
            No
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-decorator="['completeSurvey']">
          Complete Survey on Safe Answer
        </a-checkbox>
      </a-form-item>
      <h3>Preview</h3>
      <div v-html="form.getFieldValue('name')" style="min-height: 160px;max-height: 160px;overflow-y: auto;border: 1px solid #d3d3d3;border-radiu: 5px;padding: 10px;"></div>
      <br />
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span v-if="mode==='add'">Add Question</span>
          <span v-if="mode==='edit'">Edit Question</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { QuestionApi, SurveyQuestionApi } from "../../services";

export default {
  props: {
    mode: String,
    item: Object
  },
  data() {
    return {
      name: '',
      type: '',
      title: '',
      answer: '',
      globalQuestionId: null,
      globalQuestions: [],
      whileSaving: false
    }
  },
  async mounted() {
    const response = await QuestionApi.list();
    this.globalQuestions = response.data;
    if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
      }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  watch: {
     globalQuestionId: function(newValue, oldValue) {
       const item = this.globalQuestions.filter(record => record.questionId === newValue);
       this.form.setFieldsValue({title: item[0].title});
       this.form.setFieldsValue({name: item[0].name});
       this.form.setFieldsValue({answer: item[0].answer});
     },
     type: function(newValue, oldValue) {
       if (newValue === 'custom') this.globalQuestionId = null;
       this.form.setFieldsValue({title: null });
       this.form.setFieldsValue({name: null });
       this.form.setFieldsValue({answer: null });
     },
     item() {
        this.form.resetFields();
        if (this.mode === 'edit') {
          this.form.setFieldsValue({
            ...this.item
          });
        }
      },
      mode(nv, ov) {
        this.form.resetFields();
        if (this.mode === 'edit') {
          this.form.setFieldsValue({
            ...this.item
          });
        }
      }
   },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        console.log("Received values of form: ", values);
        if (!err) {
          this.form.setFieldsValue({
            type: 'custom'
          })
          try {
            if (this.mode === 'edit') {
              await SurveyQuestionApi.update(this.item.questionId, values);
            } else {
              const { id } = this.$route.params;
              await SurveyQuestionApi.create(id, values);
            }
            this.form.resetFields();
            this.$message.success(
              "Question Added Successfully"
            );
            this.$emit('success', true);
          } catch (err) {
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$error.message.error(
                "Something went wrong. Please contact administrator."
              );
            }
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>
<style scoped>

a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>