



import { Component, Vue } from "vue-property-decorator";

@Component
export default class WishBanner extends Vue {
    public banner = "Good Morning";
    
    mounted() {
        const today = new Date()
        const curHr = today.getHours()
        if (curHr < 12) {
            this.banner = "Good Morning";
        } else if (curHr < 18) {
            this.banner = "Good Afternoon";
        } else {
            this.banner = "Good Evening";
        }   
    }
}
