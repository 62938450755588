





































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SurveyQuestionModule = namespace("SurveyQuestionModule");
import { Question } from "../../store/modules/surveyquestion/types";
import SurveyQuestionAdd from "./SurveyQuestionAdd.vue";
import Icon from "../../components/Icon.vue";
import {
  sortStringAttributes,
  SurveyApi,
  QuestionApi,
  SurveyQuestionApi,
} from "../../services";

@Component({
  components: {
    Icon,
    SurveyQuestionAdd,
  },
})
export default class QuestionList extends Vue {
  @SurveyQuestionModule.State
  public questions: Question[];

  public welcomeText = ``;
  public failureText = ``;
  public successText = ``;
  public previewQuestions = [
    {
      questionId: "",
      answer: "",
    },
  ];
  public currentCounter = -1;
  public failed = false;
  public success = false;
  public loading = false;
  public visible = false;
  public surveyInfo = {
    welcomeText: "",
    successText: "",
    failureText: "",
  };
  public activeMode = "add";
  public activeItem: any = null;

  public columns = [
    {
      title: "Name",
      dataIndex: "title",
      sorter: (a, b) => sortStringAttributes(a.title, b.title),
    },
    {
      title: "Safe Answer",
      dataIndex: "answer",
      sorter: (a, b) => sortStringAttributes(a.name, b.name),
      slots: { title: "safeAnswer" },
      scopedSlots: { customRender: "safeAnswer" },
    },
    {
      title: "Complete Survey",
      dataIndex: "completeSurvey",
      sorter: (a, b) =>
        sortStringAttributes(a.completeSurvey, b.completeSurvey),
      slots: { title: "completeSurvey" },
      scopedSlots: { customRender: "completeSurvey" },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: "Action" },
    },
  ];

  @SurveyQuestionModule.Action
  public load!: (surveyId: string) => Promise<Question[]>;

  get modalTitle() {
    return this.activeMode === "add" ? "Add Question" : "Edit Question";
  }

  resetPreview() {
    this.success = false;
    this.failed = false;
    this.currentCounter = -1;
  }

  hasCustomQuestionsLeft() {
    let found = false;
    let indexAt: any = null;
    for (
      let index = this.currentCounter;
      index < this.questions.length;
      index++
    ) {
      if (this.questions[index].type === "custom") {
        found = true;
        indexAt = index;
        break;
      }
    }
    return { found, indexAt };
  }

  async savePreviewResponse(questionId, value) {
    const item = this.questions.filter(
      (record) => record.questionId === questionId
    );

    // If the choses answer is not correct & completeSurvey is false
    if (!item[0].completeSurvey && item[0].answer !== value) {
      this.failed = true;
      this.success = false;
    }

    // If choosen answer is correct & completeSurvey is true
    if (item[0].answer === value && item[0].completeSurvey) {
      if (item[0].type === "custom") {
        this.failed = false;
        this.success = true;
      } else {
        const customQuestions = this.hasCustomQuestionsLeft();
        if (!customQuestions.found) {
          this.failed = false;
          this.success = true;
        } else {
          this.currentCounter = customQuestions.indexAt;
        }
      }
    }

    // If reaches the end of the question
    if (this.currentCounter === this.questions.length) {
      if (item[0].answer === value) {
        this.success = true;
      }
    }
  }

  async loadQuestionsForSurvey() {
    try {
      const { id } = this.$route.params;
      await QuestionApi.loadCDCQuestionnaireForSurvey(id);
      await this.load(id);
      this.loadSurveyDetails(id);
      this.$message.success("Successfully loaded the global questions");
    } catch (err) {
      this.$message.error(
        "Unable to load the global questions... Please try again later"
      );
    }
  }

  async mounted() {
    const { id } = this.$route.params;
    await this.load(id);
    this.loadSurveyDetails(id);
  }

  async loadSurveyDetails(id) {
    const response = await SurveyApi.retrieve(id);
    try {
      this.surveyInfo = response.data[0];
      this.welcomeText = this.surveyInfo.welcomeText;
      this.successText = this.surveyInfo.successText;
      this.failureText = this.surveyInfo.failureText;
    } catch (err) {
      console.log(err);
    }
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch() {
    console.log("searched");
  }

  questionAdded() {
    this.visible = false;
    const { id } = this.$route.params;
    this.load(id);
  }

  async updateText() {
    const { id } = this.$route.params;
    await SurveyApi.updateText(id, {
      welcomeText: this.welcomeText,
      successText: this.successText,
      failureText: this.failureText,
    });
    this.$message.success("Survey updated successfully");
    this.loadSurveyDetails(id);
  }

  async confirmDeactivate(record) {
    try {
      await SurveyQuestionApi.delete(record.questionId);
      const { id } = this.$route.params;
      await this.load(id);
      this.$message.success("Question has been deleted");
    } catch (err) {
      this.$message.error("Failed to delete the question.");
    }
  }

  async editQuestion(record) {
    this.activeMode = "edit";
    this.activeItem = record;
    this.visible = true;
  }
}
