var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type']),expression:"['type']"}],attrs:{"disabled":_vm.whileSaving,"default-value":"custom","size":"large","placeholder":"Type*"}},[_c('a-select-option',{attrs:{"value":"custom"}},[_vm._v(" Custom ")])],1)],1),(_vm.type==='pool')?_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['globalQuestionId', {
            rules: [
              { required: true, message: 'Please select question from pool!' } ],
          } ]),expression:"['globalQuestionId', {\n            rules: [\n              { required: true, message: 'Please select question from pool!' },\n            ],\n          },]"}],attrs:{"disabled":_vm.whileSaving,"default-value":"Male","size":"large","placeholder":"Question*"}},_vm._l((_vm.globalQuestions),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.questionId}},[_vm._v(" "+_vm._s(item.title)+" | "+_vm._s(item.answer)+" ")])}),1)],1):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', {
            rules: [
              { required: true, message: 'Please input Question name!' } ],
          } ]),expression:"['title', {\n            rules: [\n              { required: true, message: 'Please input Question name!' },\n            ],\n          },]"}],attrs:{"disabled":_vm.globalQuestionId,"size":"large","placeholder":"Question Name*"}})],1),_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            rules: [
              { required: true, message: 'Please input Question Text!' } ],
          } ]),expression:"['name', {\n            rules: [\n              { required: true, message: 'Please input Question Text!' },\n            ],\n          },]"}],attrs:{"readonly":_vm.globalQuestionId,"rows":"5","size":"large","placeholder":"Question Text*"}})],1),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['answer', {
            rules: [
              { required: true, message: 'Please input Safe Answer!' } ],
          } ]),expression:"['answer', {\n            rules: [\n              { required: true, message: 'Please input Safe Answer!' },\n            ],\n          },]"}],attrs:{"disabled":_vm.whileSaving,"default-value":"yes","size":"large","placeholder":"Safe Answer*"}},[_c('a-select-option',{attrs:{"value":"yes"}},[_vm._v(" Yes ")]),_c('a-select-option',{attrs:{"value":"no"}},[_vm._v(" No ")])],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['completeSurvey']),expression:"['completeSurvey']"}]},[_vm._v(" Complete Survey on Safe Answer ")])],1),_c('h3',[_vm._v("Preview")]),_c('div',{staticStyle:{"min-height":"160px","max-height":"160px","overflow-y":"auto","border":"1px solid #d3d3d3","border-radiu":"5px","padding":"10px"},domProps:{"innerHTML":_vm._s(_vm.form.getFieldValue('name'))}}),_c('br'),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%","background":"#5168ea","border-color":"#5168ea"},attrs:{"size":"large","type":"primary","html-type":"submit"}},[_c('a-icon',{staticStyle:{"color":"white"},attrs:{"type":"lock"}}),(_vm.mode==='add')?_c('span',[_vm._v("Add Question")]):_vm._e(),(_vm.mode==='edit')?_c('span',[_vm._v("Edit Question")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }